<template>
  <div class="box">
    <img src="/temp/no_data.png" alt="logo">
    <span>{{ tip }}</span>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    tip: {
      type: String,
      default() {
        return '暂无数据'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;

  img {
    width: 100px;
  }

  span {
    margin-top: 10px;
    font-size: 20px;
  }
}
</style>

