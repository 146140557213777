import request from '@/utils/request'

// 查询花房大屏所有厅房
export function findFlowerHalls() {
  return request({
    url: `/halls/flower`,
    method: 'get'
  })
}

// 查询综合大屏所有厅房
export function findComprehensiveHalls() {
  return request({
    url: `/halls/comprehensive`,
    method: 'get'
  })
}

// 厅房详情
export function findHall(id) {
  return request({
    url: `/halls/${id}`,
    method: 'get'
  })
}

export function findSubscribeOrderQrCodeUrl(id) {
  return request({
    url: `/service_orders/${id}/wechat/subscribe_orders/qr_code_url`,
    method: 'get'
  })
}

